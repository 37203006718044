import React from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts";
import "@syncfusion/ej2-layouts/styles/material.css";
import useAgora from "./Hook";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import axios from 'axios'
import {
  BsArrowUpRight,
  BsCameraVideo,
  BsCameraVideoOff,
  BsThreeDots,
} from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import { useState } from "react";
const WebDashboard = (props) => {
  const { users } = props;
  const [useClient] = useAgora();
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const videoCallId = new URLSearchParams(window?.location?.search).get(
    "videoCallId"
  );

  console.log("newvideoCallId :>> ", videoCallId);

  const onPanelResize = (args) => {
    if (
      args.element &&
      args.element.querySelector(
        ".e-panel-container .e-panel-content div div div"
      )
    ) {
      let chartObj = args.element.querySelector(
        ".e-panel-container .e-panel-content div div div"
      ).ej2_instances[0];
      const height = args.element.querySelector(
        ".e-panel-container .e-panel-content"
      ).clientHeight;
      chartObj.height = `${height - 20}`;
      chartObj.width = "100%";
      chartObj.refresh();
    }
  };
  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {

    let body = {
      videoCallId: videoCallId,
    };

    axios
      .post(`${process.env.REACT_APP_ENDPOINT}/doctor/remove_meetingLink`, body)
      .then((res) => {
        console.log("res :>> ", res);
        if (res?.data?.status === 200) {
          tracks[0].close();
          tracks[1].close();
          window.close();
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });

  };

  const dummy = new Array(users.length < 1 ? 1 : users.length).fill(0);

  return (
    <div>
      <div
        className="content-wrapper"
        style={{ maxWidth: "95%", margin: "10px auto" }}
      >
        {/* <h4 className="text-start">Marketing Strategy Sync</h4> */}
        <div className=" position-relative">
          <DashboardLayoutComponent
            created={() => { }}
            columns={6}
            id="predefine_dashboard"
            cellSpacing={[5, 5]}
            resizeStop={onPanelResize}
            allowResizing={true}
            allowDragging={true}
          >
            <div
              id="one"
              className="e-panel"
              data-row="0"
              data-col="0"
              data-sizex="6"
            // data-sizey="2"
            // style={{ height: "80vh" }}
            >
              <span id="close" className="e-template-icon e-clear-icon" />
              <div className="e-panel-container">
                {users?.length > 0 ? (
                  <AgoraVideoPlayer
                    className="vid"
                    // videoTrack={tracks[1]}
                    videoTrack={users[0].videoTrack}
                    style={{ height: "90vh", width: "100%" }}
                  />
                ) : (
                  <div
                    className="bg-black"
                    style={{ height: "90vh", width: "100%" }}
                  ></div>
                )}
              </div>
            </div>
            <div className="position-relative">
              {dummy.map((e, index) => (
                <div
                  id={`user-${index}`}
                  className="e-panel asd"
                  // data-row="2"
                  // data-col={index}
                  // data-sizex="1"
                  // data-sizey="1"
                  key={`user-${index}`}
                >
                  <span id="close" className="e-template-icon e-clear-icon" />
                  <div className="e-panel-container">
                    {/* {users[index]?.videoTrack && ( */}
                    <AgoraVideoPlayer
                      className="vid"
                      // videoTrack={users[index].videoTrack}
                      videoTrack={tracks[1]}
                      // key={users[index].uid}
                      style={{ height: "100%", width: "100%" }}
                    />
                    {/* )} */}
                  </div>
                </div>
              ))}
            </div>
          </DashboardLayoutComponent>
          <div
            className="d-flex justify-content-center gap-3 position-absolute w-100"
            style={{ bottom: "5px", zIndex: "9999" }}
          >
            <p
              className={trackState.audio ? "on" : ""}
              onClick={() => mute("audio")}
            >
              <div
                className="p-2 rounded-pill d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(236, 240, 243, 0.3)",
                  height: "40px",
                  width: "40px",
                  cursor: "pointer",
                }}
              >
                {trackState.audio ? (
                  <BiMicrophone color="white" />
                ) : (
                  <BiMicrophoneOff color="white" />
                )}
              </div>
            </p>
            <p
              className={trackState.video ? "on" : ""}
              onClick={() => mute("video")}
            >
              <div
                className="p-2 rounded-pill d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(236, 240, 243, 0.3)",
                  height: "40px",
                  width: "40px",
                  cursor: "pointer",
                }}
              >
                {trackState.video ? (
                  <BsCameraVideo color="white" />
                ) : (
                  <BsCameraVideoOff color="white" />
                )}
              </div>
            </p>
            {
              <p onClick={() => leaveChannel()}>
                <div
                  className="p-2 rounded-pill d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "red",
                    height: "40px",
                    width: "40px",
                    cursor: "pointer",
                  }}
                >
                  <IoCallOutline color="white" />
                </div>
              </p>
            }
            {/* <p>
              <div
                className="p-2 rounded-pill d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(236, 240, 243, 0.3)",
                  height: "40px",
                  width: "40px",
                  cursor: "pointer",
                }}
              >
                <BsThreeDots color="white" />
              </div>
            </p> */}
            {/* <p>
              <div
                className="p-2 rounded-pill d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(236, 240, 243, 0.3)",
                  height: "40px",
                  width: "40px",
                  cursor: "pointer",
                }}
              >
                <BsArrowUpRight color="white" />
              </div>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebDashboard;
