import React, { useEffect, useState } from "react";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-layouts/styles/material.css";
import img from "./assets/images/card-img-3.png";
import moment from "moment";
import { BsCameraVideo, BsThreeDots } from "react-icons/bs";
import { BiLoaderAlt, BiMicrophone } from "react-icons/bi";
import axios from "axios";



const ChannelForm = (props) => {
  const patientId = new URLSearchParams(window?.location?.search).get(
    "patientId"
  );
  const doctorId = new URLSearchParams(window?.location?.search).get(
    "doctorId"
  );
  const videoCallId = new URLSearchParams(window?.location?.search).get(
    "videoCallId"
  );

  console.log("idNewNew :>> ", videoCallId);

  const { setInCall, setChannelName, setToken, setVideoCallID } = props;
  const [orderdata, setOrderdata] = useState();
  const [page, setPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const getData = () => {
    let body = {};
    if (patientId) {
      body = { page: 1, limit: 10, patientId: patientId };
    } else {
      body = {
        page: 1,
        limit: 10,
        doctorId: doctorId,
      };
    }

    axios
      .post(
        // `https://medicine.api.semicolonapi.com/${doctorId ? "doctor" : "patient"
        `${process.env.REACT_APP_ENDPOINT}/${doctorId ? "doctor" : "patient"
        // `${process.env.REACT_APP_ENDPOINT}.au/${doctorId ? "doctor" : "patient"
        }/agora/video_call/get`,
        body
      )
      .then((res) => {
        console.log("res :>> ", res?.data?.data?.video_call_data);
        // setOrderdata();
        setOrderdata(
          res?.data?.data?.video_call_data?.map((val) => {
            return {
              id: val?._id,
              name: `${val?.user[0]?.firstName} ${val?.user[0]?.lastName}`,
              sloatDate: moment(val?.slotDate).format("DD-MM-YYYY"),
              sloatTime: `${val?.slotTime?.h}:${val?.slotTime?.m}`,
            };
          })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };

  const getToken = (id) => {
    let body = {};
    if (patientId) {
      body = { videoCallId: videoCallId, patientId: patientId };
    } else {
      body = {
        videoCallId: videoCallId,
        doctorId: doctorId,
      };
    }
    axios
      .post(
        // `https://medicine.api.semicolonapi.com/${doctorId ? "doctor" : "patient"
        `${process.env.REACT_APP_ENDPOINT}/${doctorId ? "doctor" : "patient"
        // `${process.env.REACT_APP_ENDPOINT}.au/${doctorId ? "doctor" : "patient"
        }/agora/create_token`,
        body
      )
      .then((res) => {
        console.log("rse :>> ", res?.data?.data);
        setToken(res?.data?.data?.agoraToken);
        setChannelName(res?.data?.data?.channelName);
        setVideoCallID(id);
        setInCall(true);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };

  useEffect(() => {
    getData();
    getToken();
  }, []);

  return (
    <div>
      {/* <div className="wrapper-inner">
        <h1 className="heading">Join a Meeting</h1>
        <form className="join">
          <TextBoxComponent
            type="text"
            placeholder="Enter Channel Name"
            floatLabelType="Auto"
            input={({ value }) => setChannelName(value)}
            cssClass="e-outline"
          />
          <ButtonComponent
            type="submit"
            cssClass="e-info"
            style={{ fontSize: "18px", padding: "10px 20px" }}
            onClick={(e) => {
              e.preventDefault();
              setInCall(true);
            }}
          >
            Join
          </ButtonComponent>
        </form>
      </div> */}
      {/* <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3>Please wait, the meeting host will let you in soon.</h3>
          <div className="mt-5 text-secondary">Personal Meeting Room</div>
          <button class="join-button mt-3" onClick={getToken}>
            {" "}
            Join{" "}
          </button>
        </div>
      </div> */}
      {/* <div className="m-5">
        <div className="bg-white shadow rounded-2">
          <div className="p-3">
            <div className="d-flex">
              <div className="position-relative">
                <img
                  src={img}
                  alt=""
                  style={{ width: "180px", height: "150px" }}
                />
                <div
                  className="d-flex position-absolute justify-content-center w-100"
                  style={{ bottom: "10px" }}
                >
                  <div
                    className="p-1 rounded-pill d-flex align-items-center justify-content-center me-1"
                    style={{
                      height: "30px",
                      width: "30px",
                      backgroundColor: "#00000085",
                    }}
                  >
                    <BiMicrophone color="white" />
                  </div>
                  <div
                    className="p-1 rounded-pill d-flex align-items-center justify-content-center"
                    style={{
                      height: "30px",
                      width: "30px",
                      backgroundColor: "#00000085",
                    }}
                  >
                    <BsCameraVideo color="white" />
                  </div>
                </div>
              </div>
              <div
                className="ms-4 w-100"
                style={{ color: "rgba(0, 59, 97, 1)" }}
              >
                <div className=" d-flex justify-content-between w-100">
                  <h4>Marketing Strategy Sync</h4>
                  <div
                    className="p-2 rounded-pill d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: "rgba(236, 240, 243, 1)",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <BsThreeDots />
                  </div>
                </div>
                <div className="mt-1">Scheduled : 3:00 PM</div>
                <h5 className="mt-3">
                  The meeting will start at 4PM{" "}
                  <span>
                    <BiLoaderAlt
                      style={{
                        color:
                          "linear-gradient(201.8deg, #4482DE 36.9%, rgba(232, 244, 255, 0) 97.32%)",
                      }}
                    />
                  </span>
                </h5>
                <button class="join-button" onClick={getToken}>
                  {" "}
                  Join{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default ChannelForm;
