import React, { useState } from "react";
import VideoCall from "./VideoCall";
import ChannelForm from "./ChannelForm";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [videoCallID, setVideoCallID] = useState("");
  const [tokenn, setToken] = useState("");
  console.log("tokenn :>> ", tokenn);

  return (
    <div>
      {inCall ? (
        <VideoCall
          setInCall={setInCall}
          channelName={channelName}
          videoCallID={videoCallID}
          tokenn={tokenn}
        />
      ) : (
        <ChannelForm
          setInCall={setInCall}
          setChannelName={setChannelName}
          setVideoCallID={setVideoCallID}
          setToken={setToken}
        />
      )}
    </div>
  );
};

export default App;
